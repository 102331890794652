/* Use component-local class names and add them to HTML via #class_for(name) helper */

.tags-ui--tag {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  padding: 0.1rem 0.5rem;
  border-radius: 9999px; /* This creates the pill shape */
  font-weight: 500;
  white-space: nowrap;
  max-width: 14em;
  text-overflow: ellipsis !important;
  font-size: 90%;
  cursor: default;
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(0.85);
  }

  /* Purple (matching your theme) */
  &.tag-purple {
    background-color: #e2e1f1; /* Your existing accent color */
    color: #5b4d9d;
    border: 1px solid #5b4d9d;
  }

  /* Red */
  &.tag-red {
    background-color: #ffe5e5;
    color: #cc3333;
    border: 1px solid #cc3333;
  }

  /* Green */
  &.tag-green {
    background-color: #e6f5e6;
    color: #2d782d;
    border: 1px solid #2d782d;
  }

  /* Yellow */
  &.tag-yellow {
    background-color: #fff8e6;
    color: #996600;
    border: 1px solid #996600;
  }

  /* White */
  &.tag-white {
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #333;
  }

  /* Black */
  &.tag-black {
    background-color: #333333;
    color: #ffffff;
    border: 1px solid #fff;
  }

  /* Grey */
  &.tag-grey {
    background-color: #f0f0f0;
    color: #666666;
    border: 1px solid #666;
  }

}

