$ava-primary-color: #E2E1F1;
$ava-primary-dark-color: #8980BD;

$ava-secondary-color: #FDEFF4;
$ava-secondary-dark-color:#F3CEDC;
$ava-secondary-darkest-color: #C23462;

$ava-accent-color: #C6E6DE;
$ava-accent-dark-color: #51AF98;

$ava-background-color: #F3F2F5;

.ava-danger-color { 
  color: #8d021f;
}

.ava-secondary-darkest-color { 
  color: $ava-secondary-darkest-color
}